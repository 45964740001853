import React from "react"
import { Link } from "gatsby"

export default function ({ author, published, modified, tags }) {
  const Modified = () =>
    modified?.iso && modified?.pretty ? (
      <>
        {" "}
        · mod: <time dateTime={modified.iso}>{modified.pretty}</time>
      </>
    ) : (
      <></>
    )

  const Tags = () =>
    tags && (
      <li>
        <ul className="[ taglist ]">
          {tags.map(tag => (
            <li key={tag}>
              #<Link to={`/tags/${tag}`}>{tag}</Link>
            </li>
          ))}
        </ul>
      </li>
    )

  return (
    <article className="[ metadata ]">
      <div className="[ highlight ]" />
      <ul>
        {author && <li>{author}</li>}
        <li>
          <time dateTime={published.iso}>{published.pretty}</time>
          <Modified />
        </li>
        <Tags />
      </ul>
    </article>
  )
}
