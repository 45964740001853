import React from "react"
import { Link } from "gatsby"

import Metadata from "../components/metadata"

export default function ({ posts }) {
  return (
    <div>
      {posts.map(p => (
        <div key={p.path} className="[ post-preview ]">
          <h1>
            <Link className="[ post-title ]" to={p.path}>
              {p.title}
            </Link>
          </h1>

          <Metadata
            published={p.published}
            modified={p.modified}
            tags={p.tags}
          />

          <p>{p.description}</p>

          <div className="read-more">
            <Link to={p.path} aria-label={`Read ${p.title}`}>
              ...read more!
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}
