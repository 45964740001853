import React from "react"
import { graphql } from "gatsby"

import PostList from "../components/post-list"
import LightSep from "../components/light-separator"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const about = { ...data.about.metadata, content: data.about.content }

  const posts = data.posts.nodes.map(p => ({
    ...p,
    ...p.metadata,
    path: p.fields.path,
    published: {
      iso: p.metadata.published,
      pretty: p.metadata.publishedPretty,
    },
    modified: { iso: p.metadata.modified, pretty: p.metadata.modifiedPretty },
  }))
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <div className="[ content ]">
        <div className="[ heading ]">
          <div className="[ title-wrapper ]">
            <h1 className="[ title ]">
              about<span className="[ highlight break-line ]">::</span>thomas
              <span className="[ highlight break-line ]">.</span>heartman
            </h1>
          </div>
        </div>
        <div className="[ metadata ]">
          <div className="[ highlight ]" />
          <ul>
            {about.flavor && <li>{about.flavor}</li>}
            <li>{about.location}</li>
          </ul>
        </div>
        <article
          className="body"
          dangerouslySetInnerHTML={{ __html: data.about.html }}
        />
        <div className="[ post-separator subgrid ]">
          <div className="content">
            <LightSep />
          </div>
        </div>

        <article>
          <h1>Recent posts</h1>
          <p>
            Why not head on over to the blog or the archive section to start
            reading? If you're not sure where to start, how about one of these
            last three posts?
          </p>

          <PostList posts={posts} />
        </article>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    about: orgContent(metadata: { title: { eq: "home" } }) {
      html
      metadata {
        location
        flavor
      }
    }

    posts: allOrgContent(
      sort: { fields: [metadata___published], order: DESC }
      filter: { metadata: { published: { ne: null } } }
      limit: 3
    ) {
      nodes {
        fields {
          path
        }
        metadata {
          title
          description
          publishedPretty: published(formatString: "MMMM DD, YYYY")
          published
          modifiedPretty: modified(formatString: "HH:mm, MMMM DD, YYYY")
          modified
          tags
        }
      }
    }
  }
`
